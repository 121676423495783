import axios from 'axios'
import merge from 'deepmerge'
import { getIn } from 'formik'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'

import { OptionType, Suburb } from './selects/FormSelect/FormSelect.types'
import SearchFormSelect from './selects/SearchFormSelect'
import { groupBy } from './utils/groupBy'
import { uniqueArray } from './utils/uniqueArray'
import useCustomCompareMemo from './utils/useCustomCompareMemo'


const api = (url: string) => `/ajax${url}`

const csrftoken = Cookies.get('csrftoken')
const initializeSearch = () => axios.post(api('/setup-search/'), {}, { headers: { 'X-CSRFToken': csrftoken } }).then(resp => resp.data).catch(e => console.error(e))

const filterLocationOptions = (
  options,
  inputValue,
  value
) : OptionType<string>[] | null => {
  let selected_provinces
  if (Array.isArray(value) && value.length) {
    selected_provinces = value.map(v => v.province)
  } else if (!Array.isArray(value) && value) {
    selected_provinces = [ value.province ]
  }
  let selected_countries
  if (Array.isArray(value) && value.length) {
    selected_countries = value.map(v => v.country)
  } else if (!Array.isArray(value) && value) {
    selected_countries = [ value.country ]
  }
  let selected_areas
  if (Array.isArray(value) && value.length) {
    selected_areas = value.filter(v => v.value.startsWith('a_')).map(v => v.label.replace(', All Suburbs', ''))
  } else if (!Array.isArray(value) && value && value.value.startsWith('a_')) {
    selected_areas = [ value.label.replace(', All Suburbs', '') ]
  }
  if (inputValue) {
    const newOptions = options.map(o => {
      if (o.options) {
        if (selected_provinces && o.province && !selected_provinces.includes(o.province)) {
          return null
        }
        if (selected_countries && o.country && !selected_countries.includes(o.country)) {
          return null
        }
        return {
          ...o,
          options: filterLocationOptions(o.options, inputValue, value)
        }
      }
      if (o.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
        if (selected_provinces) {
          if (!selected_provinces.includes(o.province)) {
            return null
          }
        }
        if (selected_countries) {
          if (!selected_countries.includes(o.country)) {
            return null
          }
        }
        if (selected_areas) {
          if (selected_areas.some(area => o.label.startsWith(`${area}, `))) {
            return null
          }
        }
        return merge({}, o)
      }
      return null
    }).filter(o => o)
    return newOptions
  }
  return options.map(o => {
    if (o.options) {
      return {
        ...o,
        options: filterLocationOptions(o.options, inputValue, value)
      }
    }
    return o
  }).filter(o => {
    if (selected_provinces && o.province && !selected_provinces.includes(o.province)) {
      return false
    }
    if (selected_countries && o.country && !selected_countries.includes(o.country)) {
      return false
    }
    if (selected_areas) {
      if (selected_areas.some(area => o.label.startsWith(`${area}, `))) {
        return false
      }
    }
    return true
  })
}

const LocationSelect = (props: any): JSX.Element => {
  const { values } = props.form

  const buy_rent = values.listing_type?.includes('To Let') || values.listing_type?.includes('Letting') ? 'To Let' : 'For Sale'

  const category = values.listing_type?.replace(/(For Sale|To Let|Letting)/gi, '').trim()

  const [ areaOptions, setAreaOptions ] = useState<OptionType<string>[]>([])

  const [ searchData, setSearchData ] = useState(null)

  const [ locationsResponse, setLocationsResponse ] = useState<Suburb[]>([])

  const [ initialized, setInitialized ] = useState(false)

  useEffect(() => {
    const groupByCountry = groupBy(locationsResponse, 'country')

    const areaData: OptionType<string>[] = []
    Object.keys(groupByCountry).sort((a, b) => {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    }).map(country => {
      const groupByProvince = groupBy(groupByCountry[country], 'province')
      const provinceOptions = Object.keys(groupByProvince).sort().map(province => {
        const suburbs = groupByProvince[province].map((suburb: Suburb) => (
          {
            label: `${suburb.area}, ${suburb.suburb}`,
            value: `${suburb.id}`,
            province,
            country
          }
        ))
        const allSuburbs = suburbs
        allSuburbs.sort((a, b) => {
          let A: string = a.label
          let B: string = b.label
          if (A.includes('All Suburbs')) {
            A = `${a.label.split(', ')[0]}`
          }
          if (B.includes('All Suburbs')) {
            B = `${b.label.split(', ')[0]}`
          }
          if (A < B) {
            return -1
          }
          if (A > B) {
            return 1
          }
          return 0
        })
        return {
          label: province,
          options: allSuburbs,
          province,
          country
        }
      })
      areaData.push({
        label: country,
        options: provinceOptions,
        country
      })
    })
    setAreaOptions(areaData)
  }, [ useCustomCompareMemo(locationsResponse) ])

  const findLocations = () => {
    if (searchData && getIn(searchData, `${buy_rent}.${category}`)) {
      const avalableLocations = Object.keys(getIn(searchData, `${buy_rent}.${category}.locations`))
        .map(loc_id => getIn(searchData, `${buy_rent}.${category}.locations.${loc_id}`))
        .reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])

      setLocationsResponse(avalableLocations)
    }
  }

  useEffect(() => {
    initializeSearch().then(data => {
      setSearchData(data)
      setInitialized(true)
    })
  }, [ ])

  useEffect(() => {
    findLocations()
  }, [ initialized, buy_rent, useCustomCompareMemo(searchData), values.listing_type ])
  return (
    <SearchFormSelect
      {...props}
      className="feffield-location-select"
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      placeholder="Type Area or Suburb Name"
      options={areaOptions}
      isClearable={false}
      isMulti
      filterOptions={filterLocationOptions}
    />
  )
}

export default LocationSelect
