import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'
import React from 'react'

import LocationSelect from '../LocationSelect'
import { CustomErrorMessage } from '../renderField'


export default function LocationsInput({ config, field }) {
  return (
    <div className={classNames('feffield', field.classes, { 'input-group-prefix': !!field.prefix, 'input-group-suffix': !!field.suffix })} key={`${config.name}-${field.name}`}>
      {field.label ? <label htmlFor={`${config.selector.replace('#', '')}__${field.name}`} dangerouslySetInnerHTML={{ __html: field.label }} /> : null}
      <div className="input-group">
        {field.prefix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.prefix }} />}
        <Field id={`${config.selector.replace('#', '')}__${field.name}`} component={LocationSelect} name={field.name} className="input-field" placeholder={field.placeholder ? field.placeholder : ''} />
        {field.suffix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.suffix }} />}
      </div>
      <ErrorMessage name={field.name}>{msg => <CustomErrorMessage msg={msg} name={field.name} id={`${config.selector.replace('#', '')}__${field.name}`} />}</ErrorMessage>
    </div>
  )
}
