import {
  useRef
} from 'react'


export default function debounce(fn, delay = 250) {
  const timeout = useRef()

  return (...args) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
