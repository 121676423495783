import React from 'react'
import { createRoot } from 'react-dom/client'

import FrontendForm from './FrontendForm'
import { FEFormConfig } from './interfaces'
// import './index.css'


declare global {
  interface Window {
      forms:FEFormConfig[],
      request: any
      CAPTCHA_KEY: any
      log_ga_event: any
      currentElement: any
      initializeForms: any
      grecaptcha?: any
      loadingCaptcha?: boolean
  }
}

const forms = window.forms
if (typeof forms !== 'undefined') {
  try {
    forms.map((form: FEFormConfig) => {
      const selectors = Array.from(document.querySelectorAll(form.selector))

      return selectors.map(selector => {
        form.root = createRoot(selector)
        return form.root.render(
          <React.StrictMode>
            <FrontendForm config={form} />
          </React.StrictMode>
        )
      })
    })
  } catch (e) { console.error(e) }

  window.initializeForms = () => {
    forms.map((form: FEFormConfig) => {
      const selectors = Array.from(document.querySelectorAll(form.selector))
      return selectors.map(selector => {
        if (!form.root) {
          form.root = createRoot(selector)
        }
        return form.root.render(
          <React.StrictMode>
            <FrontendForm config={form} />
          </React.StrictMode>
        )
      })
    })
  }
}
