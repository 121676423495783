
import { getIn } from 'formik'
import React, { Children, isValidElement, cloneElement, ReactNode, useState, useEffect, useRef, useCallback } from 'react'
import { Options, MultiValueRemoveProps, ClearIndicatorProps, ContainerProps, components, ControlProps, MultiValueProps, ValueContainerProps, OptionProps, GroupBase } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { FormSelectProps, loadOptions, OptionType, Suburb } from './FormSelect.types'
import { MoreSearchOptionsIcon, CrossIcon } from '../../icons'
import { uniqueArray } from '../../utils/uniqueArray'


const api = (url: string) => `/v2/ajax${url}`

import type {} from 'react-select/base'
// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom property 'myCustomProp' to it.

declare module 'react-select/base' {
  export interface Props<
    Option,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    truncate?: number
    icon?: ReactNode;
  }
}

const styleProxy = new Proxy({}, {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get: () => () => {}
})

const boldMatchCharacters = ({ sentence = '', characters = '' }) => {
  const regEx = new RegExp(characters, 'gi')
  return sentence.replace(regEx, '<b>$&</b>') as string
}

const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <CrossIcon />
  </components.MultiValueRemove>
)

const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <CrossIcon />
  </components.ClearIndicator>
)

interface LocationOption {
  id?: number
  type: string
  value: string
  label: string
  name: string
  __isNew__?: boolean
}

const CustomOption = (props: OptionProps<LocationOption>) => {
  const label = props.data.__isNew__ ? `Search ${props.data.value}`
    : boldMatchCharacters({
      sentence: getIn(props, 'data.name'),
      characters: props.selectProps.inputValue
    })
  return <components.Option
    {...props}
  >
    <div className="search-option">
      <div
        className="search-option__label"
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <div className="search-option__type">
        {getIn(props, 'data.type')}
      </div>
    </div>
  </components.Option>
}

const MoreSelectedBadge = ({ items, setMoreWidth }) => {
  const title = items.join(', ')
  const length = items.length
  const label = `+${length}`

  return (
    <div className="react-select__multi-value-holder" title={title} ref={el => {
      if (el) {
        setMoreWidth(el?.offsetWidth)
      }
    }}>
      {label}
    </div>
  )
}

interface CustomMultiValueProps extends MultiValueProps {
  getValue: () => OptionType<string>[]
  truncate: number
  setMoreWidth: () => number
}

const MultiValue = ({
  index, getValue, isFocused, truncate, setMoreWidth, getClassNames, ...props
}: CustomMultiValueProps) => {
  const customClassNames = useCallback((key, p) => {
    if (index >= truncate && !isFocused && key === 'multiValue') {
      return 'react-select__multi-value--is-hidden'
    }
    return getClassNames(key, p)
  }, [ index, truncate, isFocused ])
  const overflow = getValue()
    .slice(truncate)
    .map(x => x.label)
  if ((isFinite(truncate) && index < truncate) || isFocused) {
    // @ts-ignore
    return <components.MultiValue
      {...props}
      index={index}
      getValue={getValue}
      isFocused={isFocused}
      getClassNames={customClassNames}
    />
  }

  if (index === truncate) {
    return <>
      <MoreSelectedBadge items={overflow} setMoreWidth={setMoreWidth} />
      <components.MultiValue
        {...props}
        index={index}
        getValue={getValue}
        isFocused={isFocused}
        getClassNames={customClassNames}
      />
    </>
  }

  // @ts-ignore
  return <components.MultiValue
    {...props}
    index={index}
    getValue={getValue}
    isFocused={isFocused}
    getClassNames={customClassNames}
  />
}

interface CustomValueContainerProps {
  innerProps: boolean
  isFocused: boolean
}


const SelectedValuesContainer = ({
  isDisabled,
  isFocused,
  getValue,
  children,
  ...props
}: ValueContainerProps & CustomValueContainerProps) => {
  // @ts-ignore
  const [ valueChildren, inputChild ] = children
  const [ element, setElement ] = useState<HTMLDivElement | null>()
  const [ addElement, setAddElement ] = useState<HTMLSpanElement>()
  const [ moreWidth, setMoreWidth ] = useState<number>(0)
  const [ truncate, setTruncate ] = useState<number>(0)
  const [ hidden, setHidden ] = useState<number>(0)
  const observer = useRef<IntersectionObserver>()

  const checkWidth = useCallback((entries: IntersectionObserverEntry[]) => {
    let count = 0
    const addWidth = addElement?.offsetWidth || 0
    let width = addWidth + moreWidth - 4
    for (const entry of entries) {
      width += entry.intersectionRect.width
      if (entry.rootBounds && width < entry.rootBounds.width && entry.target.classList.contains('react-select__multi-value')) {
        count++
      }
    }
    setHidden(entries.length - count)
    if (count === 0) {
      count = 1
    }
    setTruncate(count)
  }, [ addElement, moreWidth ])

  useEffect(() => {
    const addWidth = addElement?.offsetWidth || 0
    observer.current = new IntersectionObserver(checkWidth, {
      root: element,
      rootMargin: `0px 0px ${addWidth + moreWidth}px 0px`,
      threshold: 1.0
    })
    if (addElement) {
      observer.current.observe(addElement)
    }
    if (element) {
      const values = element.querySelectorAll<HTMLElement>('.react-select__multi-value')
      values.forEach(el => {
        observer.current?.observe(el)
      })
    }
    return () => {
      observer.current?.disconnect()
    }
  }, [ element, valueChildren?.length, truncate, addElement, moreWidth, hidden ])
  const container = <components.ValueContainer {...props} isDisabled={isDisabled} getValue={getValue}>
    <div className='react-select__expanding-container' ref={el => setElement(el)}>
      <components.Placeholder {...props} isDisabled={isDisabled} getValue={getValue} isFocused={isFocused}>
        {!isFocused && !props.hasValue ? props.selectProps.placeholder : null}
        {(isFocused && !addElement) || (!isFocused && valueChildren?.length) ? <span ref={el => {
          if (el) {
            setAddElement(el)
          }
        }} className="react-select__placeholder-text">Add...</span> : null}
        {inputChild}
      </components.Placeholder>
      {Children.map(valueChildren, child => {
        if (child.key === 'placeholder') {
          return null
        }
        if (isValidElement(child)) {
          // @ts-ignore
          return cloneElement(child, { isFocused, truncate, setMoreWidth })
        }
        return child
      })}
    </div>
  </components.ValueContainer>
  return (
    <>
      {props.selectProps.icon}
      {container}
    </>
  )
}

const Control = ({
  children,
  isFocused,
  ...props
}: ControlProps) => <components.Control {...props} isFocused={isFocused}>
  {Children.map(children, child => {
    if (isValidElement(child)) {
      // @ts-ignore
      return cloneElement(child, { isFocused })
    }
    return child
  })}
</components.Control>

const SelectContainer = ({
  children,
  className,
  innerProps,
  isFocused,
  ...commonProps
}: ContainerProps) => {
  const selectContainerProps = {
    ...commonProps
  }

  return (
    <components.SelectContainer
      className={`${className}${isFocused ? ' react-select__is-focused' : ''}`}
      innerProps={innerProps}
      isFocused={isFocused}
      {...selectContainerProps}
    >
      {Children.map(children, child => {
        if (isValidElement(child)) {
          // @ts-ignore
          return cloneElement(child, { isFocused })
        }
        return child
      })}
    </components.SelectContainer>
  )
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <MoreSearchOptionsIcon />
  </components.DropdownIndicator>
)

export const parseOptions = (
  options: (OptionType<string> | Suburb)[] | [],
  list: (OptionType<string> | Suburb)[] = []
) => {
  for (const option of options) {
    if (option.options) {
      parseOptions(option.options, list)
    } else {
      list.push(option)
    }
  }
  return list
}

const handleCreate = (field, form, inputValue: string) => {
  const values = getIn(form.values, field.name) || []
  values.push({
    type: 'Keyword',
    value: inputValue,
    label: inputValue,
    name: inputValue
  })
  form.setFieldValue(field.name, uniqueArray(values, 'value'))
  form.setFieldTouched(field.name)
}

export function LocationSelect<Form>({
  field,
  form,
  placeholder,
  components: comps,
  isMulti,
  hideSelectedOptions,
  types,
  className,
  allLocations,
  ...props
}: FormSelectProps<Form>): React.ReactNode {
  const value = field.value

  const fetchLocations: loadOptions = async (inputValue): Promise<Options<OptionType<string>>> => {
    if (!inputValue) {
      return []
    }
    try {
      const results = await fetch(api('/location-search/'), {
        method: 'POST',
        body: JSON.stringify({ all_locations: allLocations, types: types ? types : [ 'suburbs', 'areas' ], term: inputValue }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
      return results.map(result => ({
        value: `{"id": ${result.id}, "type": "${result.type}"}`,
        id: result.id,
        name: result.label,
        label: result.name,
        type: result.type
      })) as unknown as Options<OptionType<string>>
    } catch (e) {
      console.error(e)
    }
    return []
  }

  return <div className={`input-field select-field field-${field.name}`}>
    <AsyncCreatableSelect
      placeholder={placeholder}
      defaultValue={value}
      value={value}
      className={`react-select ${className}`}
      classNamePrefix="react-select"
      isClearable
      // @ts-ignore
      onChange={newValue => {
        if (isMulti) {
          form.setFieldValue(field.name, newValue)
          form.setFieldTouched(field.name)
        } else {
          form.setFieldValue(field.name, newValue ? newValue.value : undefined)
          form.setFieldTouched(field.name)
        }
      }}
      onCreateOption={inputValue => handleCreate(field, form, inputValue)}
      closeMenuOnSelect={true}
      hideSelectedOptions={hideSelectedOptions}
      isMulti={isMulti}
      loadOptions={fetchLocations}
      isSearchable={true}
      noOptionsMessage={() => null}
      styles={styleProxy}
      components={{
        ValueContainer: SelectedValuesContainer,
        Control,
        MultiValue,
        DropdownIndicator,
        Option: CustomOption,
        SelectContainer: SelectContainer,
        ClearIndicator,
        MultiValueRemove,
        ...comps
      }}
      {...props}
    />
  </div>
}
