import { getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { components, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'

import { FormSelectProps } from './FormSelect.types'
import debounce from '../../utils/useDebounceCallback'


interface LocationOption {
  id?: number
  type?: string
  value: string
  label: string
  name?: string
  __isNew__?: boolean
}

const boldMatchCharacters = ({ sentence = '', characters = '' }) => {
  const regEx = new RegExp(characters, 'gi')
  return sentence.replace(regEx, '<b>$&</b>') as string
}

const CustomOption = (props: OptionProps<LocationOption>) => {
  // @ts-ignore
  if (!props.selectProps.optionTemplate) {
    return <components.Option {...props}/>
  }
  const label = props.data.__isNew__ ? `Search ${props.data.value}`
    : boldMatchCharacters({
      sentence: getIn(props, 'data.label'),
      characters: props.selectProps.inputValue
    })
  // @ts-ignore
  const html = props.selectProps.optionTemplate({ ...props.data, label: label })
  return <components.Option
    {...props}
  >
    <div className="search-option" dangerouslySetInnerHTML={{ __html: html }} />
  </components.Option>
}


export function AsyncFormSelect<Form>({
  field,
  className,
  form,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  options,
  placeholder,
  components: comps,
  isMulti,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  isChecks,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  filterValues,
  creatable,
  loadOptions,
  optionTemplate,
  ...rest
}: FormSelectProps<Form>): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ inputValue, setInputValue ] = useState('')

  useEffect(() => {
    setInputValue(getIn(form.values, field.name))
  }, [ getIn(form.values, field.name) ])

  function handleInputChange(val: any, e: { action: string }) {
    if (e.action === 'input-change') {
      setInputValue(val)
    } else if (e.action === 'set-value' && !val) {
      if (!optionTemplate) {
        setInputValue(getIn(form.values, field.name))
      } else {
        setInputValue(val)
      }
    }
  }
  const debouncedCallback = debounce(loadOptions, 250)
  const Component = creatable ? CreatableSelect : AsyncSelect
  return <Component
    placeholder={placeholder}
    className={`${className} react-select react-select-autocomplete`}
    classNamePrefix="react-select"
    controlShouldRenderValue={false}
    onChange={(v: any, action) => {
      if (onChange) {
        onChange(v, action, field, form)
      } else if (isMulti) {
        form.setFieldValue(
          field.name,
          v && Array.isArray(v) ? v.map((x: { value: string }) => x.value) : undefined
        )
        form.setFieldTouched(field.name)
      } else {
        form.setFieldValue(field.name, v ? v.value : undefined)
        form.setFieldTouched(field.name)
      }
    }}
    cacheOptions
    loadOptions={debouncedCallback}
    onInputChange={handleInputChange}
    noOptionsMessage={() => ''}
    inputValue={inputValue}
    isMulti={isMulti}
    isSearchable
    unstyled
    // @ts-ignore
    optionTemplate={optionTemplate}
    components={{
      IndicatorsContainer: () => null,
      Option: CustomOption,
      ...comps
    }}
    {...rest}
  />
}
