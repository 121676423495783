import { getIn, useFormikContext } from 'formik'
import React, { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import AutoComplete from './inputs/AutoComplete'
import CheckboxInput from './inputs/CheckboxInput'
import CurrencyInput from './inputs/CurrencyInput'
import DomstringInput from './inputs/DomstringInput'
import DynamicLocationsInput from './inputs/DynamicLocationsInput'
import EmailInput from './inputs/EmailInput'
import HiddenInput from './inputs/HiddenInput'
import LocationsInput from './inputs/LocationsInput'
import MapInput from './inputs/MapInput'
import NumberInput from './inputs/NumberInput'
import RecaptchaInput from './inputs/RecaptchaInput'
import SelectInput from './inputs/SelectInput'
import SignatureInput from './inputs/SignatureInput'
import TextAreaInput from './inputs/TextAreaInput'
import TextInput from './inputs/TextInput'
import UploadInput from './inputs/UploadInput'


export const CustomErrorMessage = ({ msg, name, id }) => {
  const nodeRef = useRef(null)
  const formik = useFormikContext()
  const timer = useRef(null)
  if (typeof msg === 'object' && msg.non_field_errors) {
    msg = 'This field is invalid'
  }
  return (
    <CSSTransition
      nodeRef={nodeRef}
      appear={true}
      in={true}
      timeout={200}
      className="feffield-error"
      classNames="feffield-error"
      mountOnEnter
      unmountOnExit
      onEntered={() => {
        clearTimeout(timer.current)
        if (!window.currentElement) {
          window.currentElement = document.getElementById(id)
          if (window.currentElement) {
            window.currentElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }
        }
        timer.current = setTimeout(() => {
          if (getIn(formik.touched, name) && getIn(formik.errors, name)) {
            formik.setErrors({})
            formik.setTouched({})
            window.currentElement = null
          }
        }, 3000)
      }}
    >
      <div ref={nodeRef}>
        {msg}
      </div>
    </CSSTransition>
  )
}

export default function renderField({ config, field, props, recaptchaRef, key }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, yup, ...rest } = field
  switch (field.input) {
    case 'textarea':
      return <TextAreaInput key={key} config={config} field={rest} />
    case 'select':
      return <SelectInput key={key} config={config} field={rest} props={props} />
    case 'locations':
      return <LocationsInput key={key} config={config} field={rest} />
    case 'checkbox':
      return <CheckboxInput key={key} config={config} field={rest} />
    case 'hidden':
      return <HiddenInput key={key} config={config} field={rest} />
    case 'currency':
      return <CurrencyInput key={key} config={config} field={rest} />
    case 'domstring':
      return <DomstringInput key={key} config={config} field={rest} />
    case 'number':
      return <NumberInput key={key} config={config} field={rest} />
    case 'email':
      return <EmailInput key={key} config={config} field={rest} />
    case 'upload':
      return <UploadInput key={key} config={config} field={rest} />
    case 'recaptcha':
      return <RecaptchaInput key={key} config={config} field={rest} recaptchaRef={recaptchaRef} props={props} />
    case 'signature':
      return <SignatureInput key={key} config={config} field={rest} />
    case 'autocomplete':
      return <AutoComplete key={key} config={config} field={rest} props={props} />
    case 'map':
      return <MapInput key={key} config={config} field={rest} props={props} />
    case 'dynamic-locations':
      return <DynamicLocationsInput key={key} config={config} field={rest} props={props} />
    default: // Text field
      return <TextInput key={key} config={config} field={rest} />
  }
}
