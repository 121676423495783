/* eslint-disable import/no-named-as-default-member */
/* eslint-disable new-cap */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { NumericFormat } from 'react-number-format'


const LocalCurrencyInput = props => {
  const [ val, setVal ] = useState(props.field.value)

  useEffect(() => {
    if (val !== props.field.value) {
      setVal(props.field.value || '')
    }
  }, [ props.field.value ])

  const { prefix, suffix, readonly } = props
  const { name } = props.field // { name, value, onChange, onBlur }
  const { handleBlur, setFieldTouched, setFieldValue } = props.form // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  return (
    <>
      <NumericFormat
        className={classNames('input-field', { 'input-group-prefix': prefix, 'input-group-suffix': suffix })}
        decimalScale={2}
        thousandSeparator={','}
        value={val}
        name={`${name}formatted`}
        placeholder={props.placeholder}
        onBlur={handleBlur}
        id={`${name}-formatted`}
        onClick={e => e.target.select()}
        onValueChange={values => {
          let { value } = values
          if (!value) { value = '' }
          if (val !== value) {
            setVal(value)
            setFieldTouched(name)
            setFieldValue(name, value)
          }
        } }
        allowNegative={false}
        valueIsNumericString
        disabled={readonly}
        type='tel'
      />
      <input
        id={name}
        name={name}
        type="hidden"
        defaultValue={val}
      />
    </>
  )
}

LocalCurrencyInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

export default LocalCurrencyInput
