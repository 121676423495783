import merge from 'deepmerge'
import { getIn } from 'formik'
import React, { Children, isValidElement, cloneElement, useState, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import Select, { components, OptionProps, GroupProps, ValueContainerProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { FormSelectProps, OptionType } from './FormSelect.types'
import { MoreSearchOptionsIcon } from '../../icons'
import useCustomCompareMemo from '../../utils/useCustomCompareMemo'


const styleProxy = new Proxy({}, {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get: () => () => { }
})

const CustomOption = (props:OptionProps) => {
  const selectOption = props.selectOption
  return <components.Option
    {...props}
    selectOption={() => void 0}
    isFocused={false}
  >
    <div className="customopt">
      <label className="checkcontainer">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => selectOption(getIn(props, 'value'))}
        />
        <span className={'checkmark'}>
          {props.isSelected ? <svg viewBox="0 0 17 13"><use href="#icon16-Check-Small" /></svg> : null}
        </span>
      </label>
      {props.label}
    </div>
  </components.Option>
}
const SelectedValuesContainer = ({
  children,
  ...props
}: ValueContainerProps) => {
  const [ valueChildren, inputChild ] = children
  return (
    <components.ValueContainer {...props}>
      {Children.map(valueChildren, (child, idx) => {
        if (isValidElement(child)) {
          return cloneElement(child, { key: `${child.key}-${idx}` })
        }
        return child
      })}
      {inputChild}
    </components.ValueContainer>
  )
}


const renderNestedOption = (props: GroupProps) => {
  const {
    headingProps,
    innerProps,
    data,
    selectOption
  } = props
  const nestedOptions: readonly unknown[] = data.options
  const children = nestedOptions.map((nestedOption: any) => {
    if (nestedOption.options) {
      const rerender: GroupProps = {
        ...props,
        data: nestedOption,
        innerProps: { ...innerProps, onClick: undefined }
      }
      return renderNestedOption(rerender)
    }

    const nestedInnerProps = {
      ...innerProps,
      onClick: () => {
        selectOption(nestedOption)
      }
    }
    let isSelected = false
    const value = props.selectProps.value
    if (props.isMulti && Array.isArray(value)) {
      if (nestedOption.area && value
        .map(x => x.value).includes(nestedOption.area)) {
        isSelected = true
      } else if (
        nestedOption.province
        && value.map(x => x.province).length
        && !value
          .map(x => x.province).includes(nestedOption.province)) {
        isSelected = true
      } else {
        isSelected = value.map(x => x.value).includes(nestedOption.value)
      }
    } else {
      isSelected = props.selectProps.value === nestedOption.value
    }
    if (isSelected) { return null }
    const optionProps: any = {
      ...props,
      data: {
        ...nestedOption,
        label: nestedOption.label,
        value: nestedOption.value
      },
      isSelected
    }
    return (
      <div className="nested-optgroup-option" key={nestedOption.value}>
        <components.Option
          {...optionProps}
          innerProps={nestedInnerProps}>
          {nestedOption.label}
        </components.Option>
      </div>
    )
  }).filter(child => child)
  // Will be applied to nested optgroup headers
  const groupProps: any = {
    ...props,
    label: data.label,
    options: nestedOptions,
    headingProps: headingProps ? headingProps : { id: innerProps.id, data }
  }

  if (!children.length) {
    return null
  }
  return <components.Group key={data.label} {...groupProps}>{children}</components.Group>
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Group = (props: GroupProps) => renderNestedOption(props)


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <MoreSearchOptionsIcon />
  </components.DropdownIndicator>
)


const filterOptions = (
  options: OptionType<string>[],
  inputValue: string,
  value: OptionType<string>[] | OptionType<string>
): OptionType<string>[] => {
  if (inputValue) {
    const newOptions: any = options.map(o => {
      if (o.options) {
        return {
          ...o,
          options: filterOptions(o.options, inputValue, value)
        }
      }
      if (o.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
        return merge({}, o)
      }
      return null
    }).filter(o => o)
    return newOptions
  }
  return options
}

export const parseOptions = (
  options: OptionType<string>[] | [],
  list: OptionType<string>[] = []
) => {
  for (const option of options) {
    if (option.options) {
      parseOptions(option.options, list)
    } else {
      list.push(option)
    }
  }
  return list
}

export function FormSelect<Form>({
  field,
  form,
  options,
  placeholder,
  components: comps,
  isMulti,
  isChecks,
  onChange,
  className,
  filterValues,
  creatable,
  ...rest
}: FormSelectProps<Form>): JSX.Element {
  const [ flatOptions, setFlatOptions ] = useState<OptionType<string>[]>([])
  const [ filteredOptions, setFilteredOptions ] = useState<OptionType<string>[]>(options)
  const [ inputValue, setInputValue ] = useState('')
  useEffect(() => setFlatOptions(parseOptions(options)), [ options ])

  const values = filterValues ? (
    filterValues(getIn(form.values, field.name), flatOptions, field, form)
  ) : flatOptions.filter(x => {
    if (isMulti && Array.isArray(getIn(form.values, field.name))) {
      return getIn(form.values, field.name).includes(x.value)
    }
    return isEqual(
      x.value,
      getIn(form.values, field.name)
    )
  })

  function handleInputChange(val: any, e: { action: string }) {
    if (e.action === 'input-change') {
      setInputValue(val)
    }
  }

  const value = isMulti ? values : values[0]

  useEffect(() => {
    let newOptions
    if (rest.filterOptions) {
      newOptions = rest.filterOptions(options, inputValue, value)
    } else {
      newOptions = filterOptions(options, inputValue, value)
    }
    setFilteredOptions(newOptions)
  }, [ inputValue, useCustomCompareMemo(options), useCustomCompareMemo(value) ])

  const Component = creatable ? CreatableSelect : Select
  return <Component
    placeholder={isChecks ? `${values.length} Selected` : placeholder}
    defaultValue={value}
    value={value}
    className={`react-select${className ? ` ${className}` : ''}`}
    classNamePrefix="react-select"
    onChange={(v: any, action) => {
      if (onChange) {
        onChange(v, action, field, form)
        return
      }
      if (isMulti) {
        form.setFieldValue(
          field.name,
          v && Array.isArray(v) ? v.map((x: { value: string }) => x.value) : undefined
        )
        form.setFieldTouched(field.name)
      } else {
        form.setFieldValue(field.name, v ? v.value : undefined)
        form.setFieldTouched(field.name)
      }
    }}
    filterOption={() => true}
    onInputChange={handleInputChange}
    onMenuClose={() => setInputValue('')}
    closeMenuOnSelect={!isChecks}
    hideSelectedOptions={!isChecks}
    controlShouldRenderValue={!isChecks}
    inputValue={inputValue}
    options={filteredOptions}
    isMulti={isMulti}
    isSearchable
    styles={styleProxy}
    components={{
      Group,
      DropdownIndicator,
      ValueContainer: SelectedValuesContainer,
      Option: isChecks ? CustomOption : components.Option,
      ...comps
    }}
    {...rest}
  />
}
