import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'
import React from 'react'

import { CustomErrorMessage } from '../renderField'
import { LocationSelect } from '../selects/FormSelect/LocationSelect'


export default function DynamicLocationsInput({ config, field, props: formProps }) {
  const {
    classes,
    prefix,
    suffix,
    name,
    label,
    placeholder,
    ...props
  } = field
  return (
    <div className={classNames('feffield', classes, { 'input-group-prefix': !!prefix, 'input-group-suffix': !!suffix })} key={`${config.name}-${name}`}>
      {label ? <label htmlFor={`${config.selector.replace('#', '')}__${name}`} dangerouslySetInnerHTML={{ __html: label }} /> : null}
      <div className="input-group">
        {prefix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: prefix }} />}
        <Field id={`${config.selector.replace('#', '')}__${name}`} component={LocationSelect} name={name} className="input-field" placeholder={placeholder ? placeholder : ''} {...props} />
        {suffix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: suffix }} />}
      </div>
      <ErrorMessage name={name}>{msg => <CustomErrorMessage msg={msg} name={name} id={`${config.selector.replace('#', '')}__${name}`} />}</ErrorMessage>
    </div>
  )
}
