import { Field, ErrorMessage } from 'formik'
import React from 'react'


export default function DomstringInput({ config, field }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { classes, name, label, prefix, suffix, type, mask, onClick, ...rest } = field
  return (
    <Field id={`${config.selector.replace('#', '')}__${name}`} name={name} className="input-field" type={type ? `${type}` : 'text'} mask={mask} {...rest}>{props => (
      <div key={`${config.name}-${name}`} className={classes ? `${classes} feffield domstring` : 'feffield domstring'} onClick={onClick ? event => {
        onClick(event, field, props.form)
      } : null} dangerouslySetInnerHTML={{ __html: label ? label : '' }} />
    )}</Field>
  )
}
