import { FieldInputProps, FormikProps } from 'formik'
import React, { useState, useEffect, useRef, KeyboardEvent, MouseEvent } from 'react'


interface MaskProps {
  field: FieldInputProps<any>
  form: FormikProps<any>
  id: string
  value: string | number
  disabled?: boolean
  readonly?: boolean
  type?: string
  placeholder?: string
  onChange: (field: FieldInputProps<any>, form: FormikProps<any>, rawValue: any) => void,
  mask: string
}

const useMask = ({ initial, pattern, placeholder }) => {
  const [ rawValue, setRawValue ] = useState(initial)
  const [ maskedValue, setMaskedValue ] = useState('')
  const [ plainValue, setPlainValue ] = useState('')
  const [ lastInput, setLastInput ] = useState(0)
  const regex = new RegExp(`^${pattern}$`, 'gi')

  // Traverse the pattern to build a masked string, setting the appropriate characters.
  const applyMask = input => {
    let text = input
    if (pattern.includes('(?<') && typeof text === 'object') {
      text = Object.values(input).join('')
    }
    if (!text) { return '' }
    const charactersArray = text.split('')
    const patternArray = placeholder.split('').map(c => c.replace(/[a-zA-Z0-9]/gi, '_'))
    const combinedArray = patternArray.map(patternCharacter => {
      if (patternCharacter !== '_') { return patternCharacter }
      if (charactersArray.length === 0) { return patternCharacter }
      return charactersArray.shift()
    })
    if (lastInput !== -2) {
      setLastInput(combinedArray.indexOf('_'))
    } else {
      setLastInput(combinedArray.indexOf('_') + lastInput + 1)
    }
    return combinedArray.join('')
  }

  // Convert string to an array, filter any non-digits then rejoin into string.
  const stripMask = (input, plain = false) => {
    let value = input
    let charactersArray = []
    if (typeof input === 'string') {
      value = regex.exec(
        input
      )?.groups
      charactersArray = input.split('')
    } else if (typeof input === 'object') {
      value = Object.keys(input).map(v => input[v]).join('')
      charactersArray = value.split('')
    }


    const digitsArray = charactersArray.filter(character => /\d/.test(character))
    if (pattern.includes('(?<') && !plain && value) {
      return value
    }

    return digitsArray.join('')
  }

  // Update the masked and raw values, along with the last input string.
  const updateValue = input => {
    const newMaskedValue = applyMask(stripMask(input))
    setMaskedValue(newMaskedValue)
    setPlainValue(stripMask(newMaskedValue, true))
    setRawValue(stripMask(newMaskedValue))
  }

  useEffect(() => {
    if (initial) {
      const newMaskedValue = applyMask(stripMask(initial ? initial : ''))
      setMaskedValue(newMaskedValue)
      setPlainValue(stripMask(newMaskedValue, true))
      setRawValue(stripMask(newMaskedValue))
    }
  }, [])

  return {
    lastInput,
    maskedValue,
    rawValue,
    plainValue,
    updateValue,
    setLastInput
  }
}

interface MaskedKeyboardEvent extends KeyboardEvent<HTMLInputElement> {
  selectionStart: number
  selectionEnd: number
  value: any
}
interface MaskedMouseEvent extends MouseEvent<HTMLInputElement> {
  selectionStart: number
  selectionEnd: number
  value: any
}

const MaskedInput = (props: MaskProps): JSX.Element => {
  const { field, form, mask, onChange, ...rest } = props

  const inputRef = useRef(null)
  const inputSelectionRef = useRef(0)

  const {
    lastInput,
    maskedValue,
    rawValue,
    plainValue,
    updateValue,
    setLastInput
  } = useMask({ initial: field.value, pattern: mask, placeholder: props.placeholder })

  const onInputChanged = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement
    inputSelectionRef.current = inputRef.current.selectionStart
    updateValue(target.value)
  }

  const checkClear = (event: MaskedKeyboardEvent) => {
    if (event.key === 'Backspace') {
      setLastInput(-2)
    }
  }

  // Ensures that the cursor is placed back where it originally was after pushing new value.
  // Note: Without this, the cursor will always jump to the end of the input after typing.
  useEffect(() => {
    if (lastInput >= 0) {
      inputRef.current.setSelectionRange(lastInput, lastInput + 1)
    } else if (lastInput === -1) {
      setLastInput(maskedValue.indexOf(plainValue[inputSelectionRef.current], inputSelectionRef.current))
    }
  }, [ lastInput, plainValue ])

  // Ensures that the cursor is placed back where it originally was after pushing new value.
  // Note: Without this, the cursor will always jump to the end of the input after typing.
  useEffect(() => {
    if (onChange) {
      onChange(field, form, rawValue)
    } else {
      form.setFieldValue(field.name, rawValue)
    }
  }, [ rawValue ])

  const onClick = (event: MaskedMouseEvent) => {
    const target = event.target as HTMLInputElement
    setLastInput(target.selectionStart)
  }

  return (
    <input
      {...rest}
      ref={inputRef}
      value={maskedValue}
      onKeyDown={checkClear}
      onChange={onInputChanged}
      onFocus={() => {
        inputRef.current.setSelectionRange(0, maskedValue.length)
      }}
      onClick={onClick}
    />
  )
}

export default MaskedInput
