import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'
import React from 'react'

import MaskedInput from './MaskedInput'
import { CustomErrorMessage } from '../renderField'


export default function TextInput({ config, field }) {
  const { classes, name, label, prefix, suffix, type, mask, ...rest } = field
  return (
    <div className={classNames('feffield', classes, { 'input-group-prefix': !!prefix, 'input-group-suffix': !!suffix })} key={`${config.name}-${name}`}>
      {label ? <label htmlFor={`${config.selector.replace('#', '')}__${name}`} dangerouslySetInnerHTML={{ __html: label }} /> : null}
      <div className="input-group">
        {prefix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: prefix }} />}
        <Field id={`${config.selector.replace('#', '')}__${name}`} name={name} component={mask ? MaskedInput : undefined} className="input-field" type={type ? `${type}` : 'text'} mask={mask} {...rest} />
        {suffix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: suffix }} />}
      </div>
      <ErrorMessage name={name}>{msg => <CustomErrorMessage msg={msg} name={name} id={`${config.selector.replace('#', '')}__${name}`} />}</ErrorMessage>
    </div>
  )
}
