/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import { FieldInputProps, FormikProps, getIn } from 'formik'
import React from 'react'

import { CheckProps } from './Check.types'


/** Return the next value for a checkbox */
function getValueForCheckbox(
  currentValue: string | boolean | number | any[],
  checked: boolean,
  type: string,
  valueProp: any
) {
  let currentArrayOfValues = []
  let isValueInArray = false
  let index = -1

  // If the currentValue was not a boolean we want to return an array
  if (!Array.isArray(currentValue)) {
    // eslint-disable-next-line eqeqeq
    if (valueProp === undefined) {
      return checked
    } else if (type === 'radio') {
      return valueProp
    }
  } else {
    // If the current value is already an array, use it
    currentArrayOfValues = currentValue
    index = currentValue.indexOf(valueProp)
    isValueInArray = index >= 0
  }

  // If the checkbox was checked and the value is not already present in the array we want to add the new value to the array of values
  if (checked && valueProp !== undefined && !isValueInArray) {
    return currentArrayOfValues.concat(valueProp)
  }

  // If the checkbox was unchecked and the value is not in the array, simply return the already existing array of values
  if (!isValueInArray) {
    return currentArrayOfValues
  }

  // If the checkbox was unchecked and the value is in the array, remove the value and return the array
  return currentArrayOfValues
    .slice(0, index)
    .concat(currentArrayOfValues.slice(index + 1))
}

export const handleCheck = (
  e: React.ChangeEvent<HTMLInputElement>,
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  props: CheckProps
): React.ChangeEvent<HTMLInputElement> | null => {
  if (props.readonly) { return null }
  const value = getValueForCheckbox(getIn(form.values, field.name), e.target.checked, props.type, props.value)
  if (props.onChange) {
    props.onChange(e, field, form, value)
    return e
  }
  form.setFieldValue(e.target.name, value)
  form.handleBlur(e)
  return e
}

const Check = (props: CheckProps): JSX.Element => {
  const on_label = props.mappings?.find(o => o.value === 'on')
  const off_label = props.mappings?.find(o => o.value === 'off')
  return (
    <div className="checkinput">
      <svg aria-hidden="true" preserveAspectRatio="xMinYMin" style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <symbol id="icon16-Check-Small" viewBox="0 0 32 32">
            <path strokeMiterlimit="4" strokeLinecap="round" strokeLinejoin="round" d="M21.333 13.334l-6.667 6.667-4-4"></path>
          </symbol>
        </defs>
      </svg>
      {off_label ? <label
        className={classNames({ selected: !props.field.checked })}
        htmlFor={props.id} dangerouslySetInnerHTML={{ __html: off_label.label }}
      /> : null}
      <label className={classNames('checkcontainer', props.type, Array.isArray(props.classes) ? props.classes.filter(c => c !== 'fefcheck') : props.classes?.replace('fefcheck', ''))}>
        <div>
          <input
            id={props.id}
            className={props.field.checked ? 'on' : 'off'}
            {...props.field}
            onChange={e => handleCheck(e, props.field, props.form, props)}
            disabled={props.disabled}
            type={props.type || 'checkbox'}
          />
          <span className={`checkmark ${props.field.name}`}>
            {(props.field.checked && props.type === 'checkbox') && !on_label ? <svg viewBox="0 0 17 13" style={{ stroke: 'var(--svg-icon)' }}><use href="#icon16-Check-Small" /></svg> : null}
            {(props.field.checked && props.type === 'radio') || (on_label) ? <svg viewBox="0 0 26 26" style={{ fill: 'var(--svg-icon, #000000)' }}><circle cx="13" cy="13" r="6" /></svg> : null}
          </span>
        </div>
      </label>
      {on_label ? <label
        className={classNames({ selected: props.field.checked })}
        htmlFor={props.id} dangerouslySetInnerHTML={{ __html: on_label.label }}
      /> : null}
      {props.label ? <label htmlFor={props.id} dangerouslySetInnerHTML={{ __html: props.label }} /> : null}
    </div>
  )
}

export default Check
