/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react'


export const MoreSearchOptionsIcon = (props: unknown): React.ReactNode => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 6L8 10L12 6" strokeWidth="1.5" />
</svg>

export const SearchIcon = (props: unknown): React.ReactNode => <svg viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg">
  <path
    fillRule={'inherit'}
    d="M9 6C9 7.92969 7.42969 9.5 5.5 9.5C3.57031 9.5 2 7.92969 2 6C2 4.07031 3.57031 2.5 5.5 2.5C7.42969 2.5 9 4.07031 9 6ZM13 12.5C13 12.2344 12.8906 11.9766 12.7109 11.7969L10.0312 9.11719C10.6641 8.20312 11 7.10938 11 6C11 2.96094 8.53906 0.5 5.5 0.5C2.46094 0.5 0 2.96094 0 6C0 9.03906 2.46094 11.5 5.5 11.5C6.60938 11.5 7.70313 11.1641 8.61719 10.5312L11.2969 13.2031C11.4766 13.3906 11.7344 13.5 12 13.5C12.5469 13.5 13 13.0469 13 12.5Z"
  />
</svg>

export const CrossIcon = (props: unknown): React.ReactNode => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 4L12 12M12 4L4 12" strokeWidth="1.5" />
</svg>

export const RefreshIcon = (props: unknown): React.ReactNode => <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
  <path
    fillRule={'inherit'}
    d="M0.0740681 3.00876C0.164825 2.92157 0.323465 2.92084 0.519007 3.00803L2.23306 3.76686C3.67632 1.46672 6.24327 -4.06507e-08 9.01113 -4.06507e-08C13.4161 -4.06507e-08 17 3.58887 17 8C17 12.4111 13.4161 16 9.01113 16C6.50395 16 4.19057 14.8547 2.66393 12.8591C2.24999 12.3167 2.35256 11.5409 2.89415 11.1256C3.43575 10.7111 4.21052 10.8145 4.62446 11.3561C5.6796 12.7364 7.27784 13.5277 9.01113 13.5277C12.0548 13.5277 14.5312 11.048 14.5312 7.99994C14.5312 4.95187 12.055 2.47222 9.01113 2.47222C7.21959 2.47222 5.55211 3.36406 4.52869 4.78277L6.27153 5.55417C6.46632 5.63988 6.57259 5.75736 6.56889 5.88372C6.56594 6.01081 6.45452 6.12311 6.25604 6.19997L2.16612 7.78489C2.06356 7.82405 1.95288 7.82184 1.85252 7.7775C1.75216 7.73317 1.67396 7.65189 1.63559 7.54993L0.0528736 3.45431C-0.0246029 3.25481 -0.0164852 3.09669 0.0742722 3.00875L0.0740681 3.00876Z"
  />
</svg>

export const SharpIcon = (props: unknown): React.ReactNode =>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.519 6.49548H2.00896C1.45393 6.49548 1.01244 6.05465 1.01244 5.49896C1.01244 4.94393 1.45328 4.50244 2.00896 4.50244H14.5197C15.0747 4.50244 15.5162 4.94328 15.5162 5.49896C15.5156 6.05463 15.0747 6.49548 14.5191 6.49548H14.519Z" />
    <path d="M13.5066 11.4916H0.996521C0.44149 11.4916 0 11.0507 0 10.4951C0 9.94003 0.440837 9.49854 0.996521 9.49854H13.5073C14.0623 9.49854 14.5038 9.93937 14.5038 10.4951C14.5191 11.0507 14.0623 11.4916 13.5066 11.4916H13.5066Z" />
    <path d="M4.50816 16C4.45904 16 4.40991 16 4.36143 15.9834C3.82233 15.9017 3.43062 15.3952 3.51229 14.8568L5.52065 0.86028C5.60231 0.305249 6.10887 -0.0705207 6.6473 0.0111399C7.18639 0.0928006 7.5781 0.599358 7.49644 1.1378L5.48808 15.1343C5.42237 15.6408 4.99812 16 4.50815 16L4.50816 16Z" />
    <path d="M9.50522 15.9996C9.45609 15.9996 9.40697 15.9996 9.35848 15.9831C8.81939 15.9014 8.42768 15.3948 8.50934 14.8564L10.5177 0.859925C10.5994 0.320834 11.1059 -0.0708761 11.6444 0.0107845C12.2 0.0758582 12.5758 0.582409 12.4941 1.13744L10.5018 15.1339C10.4201 15.6405 9.99522 15.9996 9.50526 15.9996L9.50522 15.9996Z" />
  </svg>

export const PinIcon = (props: unknown): React.ReactNode => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M8 8.99545C9.104 8.99545 10 8.10002 10 6.99673C10 5.89345 9.104 4.99805 8 4.99805C6.896 4.99805 6 5.89345 6 6.99673C6 8.10002 6.896 8.99545 8 8.99545Z" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
  <path fillRule="evenodd" clipRule="evenodd" d="M14 6.99997C14 12 9 15 8 15C7 15 2 12 2 6.99997C2 3.68697 4.687 1 8 1C11.313 1 14 3.68697 14 6.99997Z" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
</svg>


export const HouseIcon = (props: unknown): React.ReactNode => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 14.9999H1V6.99988L8 0.999878L15 6.99988V14.9999Z" strokeWidth="1.5" />
</svg>

export const FiltersIcon = (props: unknown): React.ReactNode => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22.5 18H19.5M19.5 18C19.5 15.5147 17.4853 13.5 15 13.5C12.5147 13.5 10.5 15.5147 10.5 18M19.5 18C19.5 20.4853 17.4853 22.5 15 22.5C12.5147 22.5 10.5 20.4853 10.5 18M10.5 18H1.5M13.5 6C13.5 3.51472 11.4853 1.5 9 1.5C6.51472 1.5 4.5 3.51472 4.5 6M13.5 6C13.5 8.48528 11.4853 10.5 9 10.5C6.51472 10.5 4.5 8.48528 4.5 6M13.5 6H22.5M4.5 6H1.5" strokeWidth="1.5" strokeLinecap="square" />
</svg>

export const CloseFiltersIcon = (props: unknown): React.ReactNode => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 17.25L12 6.75L22.5 17.25" strokeWidth="1.5" />
</svg>
