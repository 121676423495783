import React from 'react'

import { FormSelect } from './FormSelect/FormSelect'
import { FormSelectProps } from './FormSelect/FormSelect.types'
// import { StylesConfig } from "react-select";

const SearchFormSelect = (props: FormSelectProps<any>) => <FormSelect {...props} />


export default SearchFormSelect
