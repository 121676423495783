import classNames from 'classnames'
import { ErrorMessage, Field } from 'formik'
import React from 'react'

import Map from '../Map/Map'
import { CustomErrorMessage } from '../renderField'


export default function MapInput({ config, field, props }) {
  if (!field.apiKey) { return null }
  return (
    <div className={classNames('feffield map-input', field.classes, { 'input-group-prefix': !!field.prefix, 'input-group-suffix': !!field.suffix })} key={`${config.name}-${field.name}`}>
      {field.label ? <label htmlFor={`${config.selector.replace('#', '')}__${field.name}`} dangerouslySetInnerHTML={{ __html: field.label }} /> : null}
      <div className="input-group">
        {field.prefix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.prefix }} />}
        <Field component={Map} {...field} id={`${config.selector.replace('#', '')}__${field.name}`} className="input-field" {...props} />
        {field.suffix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.suffix }} />}
      </div>
      <ErrorMessage name={field.name}>{msg => <CustomErrorMessage msg={msg} name={field.name} id={`${config.selector.replace('#', '')}__${field.name}`} />}</ErrorMessage>
    </div>
  )
}
