/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable new-cap */
import { FieldInputProps, FormikProps, getIn } from 'formik'
import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import SignaturePad from 'react-signature-canvas'
import trimCanvas from 'trim-canvas'
import './fonts.css'
import classNames from 'classnames'


interface UploadProps {
  field: FieldInputProps<any>
  form: FormikProps<any>
  id: string
  value: string | number
  disabled?: boolean
  modelname: string
  nameField: string | string[]
  readonly?: boolean
  type?: string
  placeholder?: string
}

Modal.setAppElement(document.body)

const useDraw = ({ font, text, save }, Component, deps) => {
  const [ context, setContext ] = useState<HTMLCanvasElement>(null)
  useEffect(() => {
    if (text && context) {
      const ctx = context.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, context.width, context.height)

      ctx.font = `48px '${font}'`
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      const metrics = ctx.measureText(text)
      const textWidth = metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight
      let ypos = context.height / 2
      let xpos = context.width / 2
      if (textWidth > context.width) {
        const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent

        const scalex = (context.width / textWidth)
        const scaley = (context.height / textHeight)

        ypos = (context.height / 2) * scalex
        xpos = textWidth / 2
        ctx.scale(scalex, scalex)
      }
      ctx.fillText(text, xpos, ypos, 300)
    }
  }, [ text, context, ...deps ])
  return <div key={font} className="signature__option"><Component ref={setContext} onClick={() => save(context)} /></div>
}

const SignatureInput = (props: UploadProps) => {
  const { field, form, ...rest } = props

  const [ file, setFile ] = useState(field.value)
  const [ value, setValue ] = useState(field.value?.file)
  const [ open, setOpen ] = useState(false)
  const [ tab, setTab ] = useState('text')
  const ctx_pad = useRef(null)

  let text = getIn(form.values, rest.nameField)
  if (Array.isArray(rest.nameField)) {
    text = rest.nameField.map(f => getIn(form.values, f)).join(' ')
  }

  const families = [
    'Ephesis',
    'Great Vibes',
    'Mrs Saint Delafield',
    'Tangerine'
  ]

  const removeFile = () => {
    setFile(null)
    setValue(null)
  }

  const chooseFile = e => {
    try {
      const el = e.target
      const file = el.files[0]
      if (file) {
        setFile(file)
        setValue(URL.createObjectURL(file))
        setOpen(false)
      }
    } catch (er) {
      console.error(er)
    }
  }

  const clear = () => {
    ctx_pad.current.clear()
  }

  const save = context => {
    const trimmed = getTrimmedCanvas(context)
    const url = trimmed.toDataURL('image/png')
    trimmed.toBlob(blob => {
      blob.filename = 'signature.png'
      setFile(blob)
      setOpen(false)
    }, 'image/png')
    setValue(url)
  }

  const getTrimmedCanvas = canvas => {
    // copy the canvas
    const copy = document.createElement('canvas')
    copy.width = canvas.width
    copy.height = canvas.height
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    copy.getContext('2d')!.drawImage(canvas, 0, 0)
    // then trim it
    return trimCanvas(copy)
  }

  useEffect(() => {
    if (file) {
      form.setFieldValue(field.name, file)
    } else if (field.value) {
      form.setFieldValue(field.name, null)
    }
  }, [ file ])

  const options = families.map((f, fid) => useDraw({
    font: f,
    text: text,
    save
  }, 'canvas', [ open ]))

  useEffect(() => {
    if (file) {
      form.setFieldValue(field.name, file)
    } else if (field.value) {
      form.setFieldValue(field.name, null)
    }
  }, [ file ])

  return value ? (
    <div className="inlineupload" style={{ flex: '1 1 100%', display: 'flex' }}>
      <img src={value} />
      <div>
        <button type="button" title="Remove file" className="upload-button" onClick={removeFile}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ stroke: 'var(--svg-icon)' }}>
            <path d="M3.33325 4.66667H12.6666M11.9999 4.66667V12C11.9999 12.7367 11.4033 13.3333 10.6666 13.3333H5.33325C4.59659 13.3333 3.99992 12.7367 3.99992 12V4.66667M9.99992 2.5H5.99992M6.66659 7.33333V10.6667M9.33325 7.33333V10.6667" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  ) : (
    <div className="inlineupload">
      <button type="button" className="upload-button" onClick={() => setOpen(true)}>
        Add a signature
      </button>
      {families.map((f, fid) => <span key={f} style={{ fontFamily: `"${f}"`, position: 'absolute', opacity: 0 }}>.</span>)}
      <Modal
        isOpen={open}
        className="FormSignature__Content"
        portalClassName="FormSignature"
        overlayClassName="FormSignature__Overlay"
        style={{
          ...Modal.defaultStyles,
          content: {
            position: 'absolute',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: '280px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
        contentLabel="Set Signature"
      >
        <div className="signature__tabs">
          <button onClick={() => setTab('text')} className={classNames({ active: tab === 'text' })}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 12H21M11 5H21M3.01999 4.508L4.67299 5.996L7.99999 3M11 19H21M6.41399 10.586C7.19499 11.367 7.19499 12.633 6.41399 13.414C5.63299 14.195 4.36699 14.195 3.58599 13.414C2.80499 12.633 2.80499 11.367 3.58599 10.586C4.36699 9.805 5.63299 9.805 6.41399 10.586ZM6.41399 17.586C7.19499 18.367 7.19499 19.633 6.41399 20.414C5.63299 21.195 4.36699 21.195 3.58599 20.414C2.80499 19.633 2.80499 18.367 3.58599 17.586C4.36699 16.805 5.63299 16.805 6.41399 17.586Z" stroke="#000000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>Text</span>
          </button>
          <button onClick={() => setTab('draw')} className={classNames({ active: tab === 'draw' })}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H16.172C16.702 21 17.211 20.789 17.586 20.414L20.414 17.586C20.789 17.211 21 16.702 21 16.172V11M16 21V17C16 16.448 16.448 16 17 16H21M9 15L12.15 14.611C12.371 14.584 12.577 14.483 12.735 14.326L20.366 6.695C21.211 5.85 21.211 4.48 20.366 3.634C19.521 2.789 18.151 2.789 17.305 3.634L9.745 11.194C9.592 11.347 9.493 11.545 9.462 11.76L9 15Z" stroke="#000000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>Draw</span>
          </button>
          <button onClick={() => setTab('upload')} className={classNames({ active: tab === 'upload' })}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0001 16.0018L16.588 11.4138C17.3693 10.6328 18.6359 10.6328 19.4172 11.4138L21.0038 13.0005M3.34948 18.6469L7.58125 14.4151C8.36257 13.634 9.6291 13.634 10.4104 14.4151L16.8301 20.8348M2.99634 6.998C2.99634 4.78795 4.78795 2.99634 6.998 2.99634H17.0022C19.2122 2.99634 21.0038 4.78795 21.0038 6.998V17.0022C21.0038 19.2122 19.2122 21.0038 17.0022 21.0038H6.998C4.78795 21.0038 2.99634 19.2122 2.99634 17.0022V6.998ZM8.99884 7.49821C8.99884 8.32699 8.32699 8.99884 7.49821 8.99884C6.66944 8.99884 5.99759 8.32699 5.99759 7.49821C5.99759 6.66944 6.66944 5.99759 7.49821 5.99759C8.32699 5.99759 8.99884 6.66944 8.99884 7.49821Z" stroke="#000000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>Upload</span>
          </button>
          <button className="close" onClick={() => setOpen(false)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4L20 20M20 4L4 20" stroke="#000000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>Close</span>
          </button>
        </div>
        <div className="signature__body">
          {(tab === 'text') ? (
            <>
              {options}
            </>
          ) : null}
          {(tab === 'draw') ? (
            <div className="signature__pad">
              <div className="signature__pad__canvas">
                <SignaturePad
                  ref={ctx_pad}
                  canvasProps={{
                    className: 'signaturepad'
                  }}
                />
              </div>
              <div className="signature__pad__actions">
                <button className="signature__button" onClick={clear}>Clear</button>
                <button className="signature__button save" onClick={() => save(ctx_pad.current.getCanvas())}>Save</button>
              </div>
            </div>
          ) : null}
          {(tab === 'upload') ? (
            <div className="inlineupload">
              {file ? (
                <>
                  <div>{file.name}</div>
                  <div>
                    <button type="button" className="signature__button save" onClick={removeFile}>Remove file</button>
                  </div>
                </>
              ) : (
                <button type="button" className="signature__button save" style={{ position: 'relative' }}>
                  <input
                    id={`${field.name}-upload`}
                    type="file"
                    onChange={chooseFile}
                    {...rest}
                  />
                  Upload a file
                </button>
              )}
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  )
}

export default SignatureInput
