import { Field } from 'formik'
import React from 'react'


export default function HiddenInput({ config, field }) {
  if (field.value) {
    return <Field key={`${config.name}-${field.name}`} id={`${config.selector.replace('#', '')}__${field.name}`} name={field.name} className="input-field" type="hidden" value={field.value} />
  }
  return null
}
