import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'
import React from 'react'

import { CustomErrorMessage } from '../renderField'
import Signature from '../Signature/Signature'


export default function NumberInput({ config, field }) {
  const { prefix, suffix, label, name, classes, ...rest } = field
  return (
    <div className={classNames('feffield', classes, { 'input-group-prefix': !!prefix, 'input-group-suffix': !!suffix })} key={`${config.name}-${name}`}>
      {label ? <label htmlFor={`${config.selector.replace('#', '')}__${name}`} dangerouslySetInnerHTML={{ __html: label }} /> : null}
      <div className="input-group">
        {prefix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: prefix }} />}
        <Field component={Signature} id={`${config.selector.replace('#', '')}__${name}`} name={name} className="input-field" {...rest} />
        {suffix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: suffix }} />}
      </div>
      <ErrorMessage name={name}>{msg => <CustomErrorMessage msg={msg} name={name} id={`${config.selector.replace('#', '')}__${name}`} />}</ErrorMessage>
    </div>
  )
}
