import { GoogleMap, Libraries, Marker, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import { getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'

import country_bounds from './country-bounds.json'
import { MapProps } from './Map.types'


const libraries: Libraries = [ 'places' ]

const svgMarker = ({ color }) => `data:image/svg+xml,${encodeURIComponent(`<svg width="31" height="38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.5 15.257c0 13.166-11.553 20.551-14.382 22.166a1.248 1.248 0 0 1-1.238 0C12.052 35.807.5 28.42.5 15.257a15 15 0 1 1 30 0Z" style="fill:${color};fill-opacity:1"/><path d="M8 15.257a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0Z" fill="#fff" style="fill:#fff;fill-opacity:1"/></svg>`)}`

const Map = ({
  apiKey,
  form,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  field,
  showSearch,
  countryCode,
  latInput,
  lngInput,
  mapOptions,
  markerOptions,
  onPlacesChanged,
  onMarkerDragEnd
}: MapProps): React.ReactNode => {
  const isLoaded = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries
  }).isLoaded

  const { google } = window
  const [ map, setMap ] = useState(null)
  const [ cb, setCB ] = useState(null)
  const [ searchBox, setSearchBox ] = useState<any>()
  const [ searchInput, setSearchInput ] = useState<any>()
  const [ center, setCenter ] = useState<any>({ lat: 0, lng: 0 })
  const latValue = getIn(form.values, latInput)
  const lngValue = getIn(form.values, lngInput)
  const callback = () => {
    setMap(null)
  }
  const onUnmount = useCallback(callback, [])

  useEffect(() => {
    if (latValue && lngValue && map) {
      map.panTo(new google.maps.LatLng({ lat: latValue, lng: lngValue }))
      map.setZoom(16)
    }
  }, [ latValue, lngValue, map ])

  useEffect(() => {
    if (map && google) {
      const bounds = getIn(country_bounds, `${(countryCode || 'ZA').toUpperCase()}.bounds`)
      const sw = new google.maps.LatLng({ lat: bounds.south, lng: bounds.west })
      const ne = new google.maps.LatLng({ lat: bounds.north, lng: bounds.east })
      const center_bounds = new google.maps.LatLngBounds()
      center_bounds.extend(sw)
      center_bounds.extend(ne)
      const country_center = center_bounds.getCenter()
      const lat = isNaN(latValue) ? country_center.lat() : Number(latValue)
      const lng = isNaN(lngValue) ? country_center.lng() : Number(lngValue)
      map.fitBounds(center_bounds)
      setCB(center_bounds)
      setCenter({ lat, lng })
    }
  }, [ map, google ])

  try {
    if (!isLoaded || !google) { return null }
    const style = getComputedStyle(document.body)
    const color = style.getPropertyValue('--colours-primary-7')
    let lat = 0
    let lng = 0
    if (latValue && lngValue) {
      lat = Number(latValue)
      lng = Number(lngValue)
    }
    return isLoaded && center ? (
      <GoogleMap
        center={center}
        options={{
          fullscreenControl: false,
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: true,
          disableDefaultUI: false,
          gestureHandling: 'cooperative',
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DEFAULT,
            position: google.maps.ControlPosition.BOTTOM_LEFT
          },
          ...mapOptions
        }}
        onLoad={setMap}
        onUnmount={onUnmount}
      >
        {showSearch ? (
          <StandaloneSearchBox
            bounds={cb}
            onLoad={el => {
              if (el && !searchBox) {
                setSearchBox(el)
              }
            }}
            onPlacesChanged={() => { onPlacesChanged(searchBox.getPlaces(), map) }}
          >
            <div className="form-group">
              <div className="map-search forminput not-required">
                <input ref={el => {
                  if (el && !searchInput) {
                    setSearchInput(el)
                  }
                }} className="form-control" type="text" placeholder="Enter an address" />
              </div>
            </div>
          </StandaloneSearchBox>
        ) : null}
        {(latValue && lngValue && google) ? (
          <Marker
            position={{ lat, lng }}
            draggable
            onDragEnd={onMarkerDragEnd}
            icon={svgMarker({ color })}
            {...markerOptions}
          />
        ) : null
        }
      </GoogleMap>
    ) : <></>
  } catch (e) {
    console.warn('Unable to load Google Maps component', e)
    return null
  }
}

export default Map
