// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tab__content {
  display: grid; /* 1 */
  grid-template-rows: 0fr; /* 2 */
  transition: grid-template-rows 0.5s ease-in-out, overflow 1s; /* 3 */
  overflow: hidden;
}
.tab__inner {
  min-height: 0;
  transition: visibility 1s;
  visibility: hidden;
}
.tab__input:checked ~ .tab__content {
  grid-template-rows: 1fr; /* 2 */
}
.tab__input:checked ~ .tab__content.appear-done {
  overflow: visible;
}
.tab__input:checked ~ .tab__content.enter-done {
  overflow: visible;
}
.tab__input:checked ~ .tab__content .tab__inner {
  visibility: visible;
}

.inlineupload input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  border: 0 none;
  outline: 0;
  width: 100%;
}

.ffcluster {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/FrontendForm.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;AACA;EACE,aAAa,EAAE,MAAM;EACrB,uBAAuB,EAAE,MAAM;EAC/B,4DAA4D,EAAE,MAAM;EACpE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,uBAAuB,EAAE,MAAM;AACjC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;EACR,UAAU;EACV,SAAS;EACT,UAAU;EACV,cAAc;EACd,UAAU;EACV,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".tab__input {\n  position: absolute;\n  opacity: 0;\n  z-index: -1;\n}\n.tab__content {\n  display: grid; /* 1 */\n  grid-template-rows: 0fr; /* 2 */\n  transition: grid-template-rows 0.5s ease-in-out, overflow 1s; /* 3 */\n  overflow: hidden;\n}\n.tab__inner {\n  min-height: 0;\n  transition: visibility 1s;\n  visibility: hidden;\n}\n.tab__input:checked ~ .tab__content {\n  grid-template-rows: 1fr; /* 2 */\n}\n.tab__input:checked ~ .tab__content.appear-done {\n  overflow: visible;\n}\n.tab__input:checked ~ .tab__content.enter-done {\n  overflow: visible;\n}\n.tab__input:checked ~ .tab__content .tab__inner {\n  visibility: visible;\n}\n\n.inlineupload input {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  opacity: 0;\n  margin: 0;\n  padding: 0;\n  border: 0 none;\n  outline: 0;\n  width: 100%;\n}\n\n.ffcluster {\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
